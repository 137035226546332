import React from 'react';
import styled, { css } from 'styled-components';

export default ({ children, customHeight }) => (
  <LineBreak customHeight={customHeight}>{children}</LineBreak>
);

const LineBreak = styled.br`
  display: block;
  content: '';
  font-size: 1rem;

  ${({ customHeight = '1.55em' }) => customHeight && css`margin-top ${customHeight}`};

  @media screen and (min-width: 48em) {
    font-size: 1.325rem;
  }
`;
