import React from 'react';
import { NoteIcon } from 'images';
import styled from 'styled-components';

export default ({ children, fontSize, fontWeight, lineHeight, marginTop }) => (
  <Note fontSize={fontSize} fontWeight={fontWeight} lineHeight={lineHeight} marginTop={marginTop}>
    {
      <div>
        <NoteIcon width="1.25em" />
      </div>
    }
    {children}
  </Note>
);

const Note = styled.div`
  background-color: var(--amy-light-grey);
  border-radius: 0 0.25em 0.25em 0.25em;
  margin-top: ${({ marginTop }) => marginTop};
  max-width: 51.25em;
  padding: 1.25em 1.5em;
  position: relative;

  > div {
    align-items: center;
    background-color: var(--amy-light-blue);
    border-radius: 0.25em 0.25em 0 0;
    display: flex;
    height: 2.875em;
    justify-content: center;
    left: 0;
    position: absolute;
    top: -2.875em;
    width: 2.875em;
  }

  p {
    font-size: ${({ fontSize = '1rem' }) => fontSize};
    font-weight: ${({ fontWeight = 400 }) => fontWeight};
    line-height: ${({ lineHeight }) => lineHeight};
  }

  a {
    color: var(--amy-light-blue);
    text-decoration: none;
  }
`;
