import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --amy-blue: #0279A9;
    --amy-light-blue: #009AD8;
    --amy-lightest-blue: #B6DCF5;
    --amy-dark-blue: #1F3465;
    --amy-lightest-blue-transparent: rgba(182, 220, 245, 0.3);
    --amy-background-dark-grey: #9D9D9D;
    --amy-background-lightest-blue: rgba(182, 220, 245, 0.2);
    --amy-background-light-blue: rgba(0, 154, 216, 0.2);
    --amy-background-blue: rgba(2, 121, 169, 0.2);
    --amy-background-dark-blue: rgba(31, 52, 101, 0.2);
    --amy-light-grey: rgba(229, 229, 229, 0.5);
    --amy-white: #FFFFFF;
    --amy-highlight-red: #EA5516;
    --amy-highlight-red-transparent: rgba(240, 222, 222, 0.5);
  }

  html {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100;
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  /* Scrollbar styling if viewport is over 1460px */
  ${
    '' /* body::-webkit-scrollbar {
    width: 0.625em;
  }
  body::-webkit-scrollbar-track {
    background-color: ${promozooBlack};
  }
  body::-webkit-scrollbar-thumb {
    background-color: ${promozooRed};
    border-radius: 5px;
  } */
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  body {
    color: #000;
    font-family: open-sans, sans-serif;
  }

  .carousel .slide {
    background-color: #fff;
  }
`;
