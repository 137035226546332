import React from 'react';
import styled from 'styled-components';

export default ({ children }) => <Paragraph>{children}</Paragraph>;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.625em;
  a {
    color: black;
    text-decoration: none;
  }
  sup {
    font-size: 0.625em;
    text-decoration: none;
  }

  @media screen and (min-width: 48em) {
    font-size: 1.25rem;
    line-height: 1.6em;
  }
`;
