import React, { PureComponent } from 'react';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { LinkContents } from 'components';
import { StaticQuery, graphql, Link } from 'gatsby';
import rightFacingArrow from 'images/right_facing_arrow.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

class Hero extends PureComponent {
  getActiveStyle = (backgroundColor, viewportWidth) => {
    return {
      backgroundColor,
      color: '#fff',
      height:
        viewportWidth > 999 && viewportWidth < 1200
          ? '3.6em'
          : viewportWidth >= 1200
          ? '3.333em'
          : '2.6em'
    };
  };

  render() {
    const {
      viewportWidth,
      pathname,
      selectedCarouselSlide,
      updateActiveAnchor,
      scrollToAnchor,
      handleCarousel,
      patientStoryDropMenuIsOpen,
      handlePatientStoryDropMenuIsOpen
    } = this.props;

    return (
      <StaticQuery
        query={graphql`
          {
            manByTheSeaMobile: file(
              relativePath: { eq: "PatientStoriesAndResources/man_with_back_to_sea_mobile.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 767, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            manByTheSea: file(
              relativePath: { eq: "PatientStoriesAndResources/man_with_back_to_sea.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            donegalCoastMobile: file(
              relativePath: { eq: "GeneticsAndT60aRoots/donegal_coast_mobile.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 767, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            donegalCoast: file(relativePath: { eq: "GeneticsAndT60aRoots/donegal_coast.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80, cropFocus: SOUTHEAST) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            womanLookingOutOverSeaMobile: file(
              relativePath: { eq: "SymptomsAndDiagnosis/woman_looking_out_over_sea_mobile.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 767, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            womanLookingOutOverSea: file(
              relativePath: { eq: "SymptomsAndDiagnosis/woman_looking_out_over_sea.jpg" }
            ) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={({
          manByTheSeaMobile,
          manByTheSea,
          donegalCoast,
          donegalCoastMobile,
          womanLookingOutOverSeaMobile,
          womanLookingOutOverSea
        }) => {
          const manByTheSeaSources = [
            manByTheSeaMobile.childImageSharp.fluid,
            {
              ...manByTheSea.childImageSharp.fluid,
              media: '(min-width: 48em)'
            }
          ];
          const donegalCoastSources = [
            donegalCoastMobile.childImageSharp.fluid,
            {
              ...donegalCoast.childImageSharp.fluid,
              media: '(min-width: 48em)'
            }
          ];
          const womanLookingOutOverSeaSources = [
            womanLookingOutOverSeaMobile.childImageSharp.fluid,
            {
              ...womanLookingOutOverSea.childImageSharp.fluid,
              media: '(min-width: 48em)'
            }
          ];
          return (
            <Wrapper id="top">
              <Carousel
                selectedItem={selectedCarouselSlide}
                transitionTime={580}
                showThumbs={false}
                showArrows={false}
                showIndicators={false}
                showStatus={false}>
                <CarouselImg fluid={manByTheSeaSources} loading="eager" alt="woman by the sea" />
                <CarouselImg fluid={donegalCoastSources} loading="eager" alt="donegal coast" />
                <CarouselImg
                  fluid={womanLookingOutOverSeaSources}
                  alt="woman looking out over sea"
                  loading="eager"
                />
              </Carousel>
              <H1 pathname={pathname}>
                The story of Hereditary ATTR (hATTR) Amyloidosis in the Northwest of Ireland
                {pathname === '/' && viewportWidth > 1020 && (
                  <MenuWrapper>
                    <Menu onClick={handlePatientStoryDropMenuIsOpen}>
                      <LinkContents
                        backgroundColor="--amy-white"
                        text="View Patient Stories"
                        fontSize={viewportWidth < 1100 ? '1rem' : '1.25rem'}
                        fontWeight="600"
                        height="3em"
                        iconBackgroundColor="--amy-dark-blue"
                        iconSVG={rightFacingArrow}
                        iconSVGAlt="right facing arrow"
                        iconSize="1.2em"
                        textAlign="left"
                        maxWidth="430px"
                        patientStoryDropMenuIsOpen={patientStoryDropMenuIsOpen}
                      />
                    </Menu>
                    <Dropdown patientStoryDropMenuIsOpen={patientStoryDropMenuIsOpen}>
                      <Link
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePatientStoryDropMenuIsOpen(e);
                          updateActiveAnchor(null);
                          scrollToAnchor('james-and-pat-coyles-story');
                        }}>
                        <LinkContents
                          backgroundColor="--amy-white"
                          text="View James and Pat Coyle's Story"
                          fontSize={viewportWidth < 1100 ? '1rem' : '1.25rem'}
                          fontWeight="600"
                          height="3em"
                          iconBackgroundColor="--amy-blue"
                          iconSVG={rightFacingArrow}
                          iconSVGAlt="right facing arrow"
                          iconSize="1.2em"
                          textAlign="left"
                          minWidth="430px"
                          maxWidth="430px"
                          style={{ whiteSpace: 'nowrap' }}
                        />
                      </Link>
                      <Link
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePatientStoryDropMenuIsOpen(e);
                          updateActiveAnchor(null);
                          scrollToAnchor('rosalines-story');
                        }}>
                        <LinkContents
                          backgroundColor="--amy-white"
                          text="View Rosaline's Story"
                          fontSize={viewportWidth < 1100 ? '1rem' : '1.25rem'}
                          fontWeight="600"
                          height="3em"
                          iconBackgroundColor="--amy-blue"
                          iconSVG={rightFacingArrow}
                          iconSVGAlt="right facing arrow"
                          iconSize="1.2em"
                          textAlign="left"
                          minWidth="430px"
                          maxWidth="430px"
                        />
                      </Link>
                    </Dropdown>
                  </MenuWrapper>
                )}
              </H1>
              <HeroPageLinkWrapper
                onClick={(e) => {
                  updateActiveAnchor(null);
                  handleCarousel(e.target.getAttribute('href'));
                }}>
                <HeroPageLink
                  to="/"
                  ariaLabel="patient stories and resources"
                  activeStyle={this.getActiveStyle('#009AD8', viewportWidth)}
                  inactiveFontColor="--amy-blue"
                  bottom="9.0505em">
                  Patient Stories + Resources
                  {viewportWidth > 999 && (
                    <ActiveDownArrow
                      isActive={pathname === '/'}
                      activeBackgroundColor="--amy-light-blue"
                    />
                  )}
                </HeroPageLink>
                <HeroPageLink
                  to="/genetics-and-t80a-roots/"
                  ariaLabel="genetics and t80A roots"
                  activeStyle={this.getActiveStyle('#1F3465', viewportWidth)}
                  inactiveFontColor="--amy-dark-blue"
                  bottom="5.8785em">
                  Genetics + T80A Roots
                  {viewportWidth > 999 && (
                    <ActiveDownArrow
                      isActive={pathname === '/genetics-and-t80a-roots/'}
                      activeBackgroundColor="--amy-dark-blue"
                    />
                  )}
                </HeroPageLink>
                <HeroPageLink
                  to="/symptoms-and-diagnosis/"
                  ariaLabel="symptoms and diagnosis"
                  activeStyle={this.getActiveStyle('#0279A9', viewportWidth)}
                  inactiveFontColor="--amy-blue"
                  bottom="2.7075em">
                  Symptoms + Diagnosis
                  {viewportWidth > 999 && (
                    <ActiveDownArrow
                      isActive={pathname === '/symptoms-and-diagnosis/'}
                      activeBackgroundColor="--amy-blue"
                    />
                  )}
                </HeroPageLink>
              </HeroPageLinkWrapper>
            </Wrapper>
          );
        }}
      />
    );
  }
}

const Wrapper = styled.div`
  position: relative;
`;

const CarouselImg = styled(Img)`
  max-width: 120em;
  width: 100%;

  @media screen and (min-width: 48em) {
    height: calc(100vh);
    max-height: 76.561em;
  }

  img {
    top: -2.5005em !important;
  }
`;

const H1 = styled.h1`
  color: ${({ pathname }) =>
    pathname === '/symptoms-and-diagnosis/' ? '#fff' : pathname === '/' ? '#fff' : '#000'};
  font-size: 1.4rem;
  font-weight: 700;
  left: 4.1vw;
  letter-spacing: 0.01em;
  line-height: 1.471em;
  position: absolute;
  transition: color 580ms ease-in-out;
  top: 41vw;
  width: 8.5em;
  a {
    text-decoration: none;
  }
  @media screen and (min-width: 22.5em) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 23.438em) {
    top: 40.25vw;
    line-height: 1.643em;
  }

  @media screen and (min-width: 28.125em) {
    top: 38.75vw;
    font-size: 2rem;
  }

  @media screen and (min-width: 34.375em) {
    font-size: 2.4rem;
  }

  @media screen and (min-width: 40.625em) {
    font-size: 2.9rem;
  }

  @media screen and (min-width: 48em) {
    /* 768px */
    font-size: 2.5rem;
    line-height: 1.359em;
    text-shadow: 0px 0.063em 0.063em rgba(0, 0, 0, 0.25);
    top: 29.5vh;
    width: 13em;
  }

  @media screen and (min-width: 62.5em) {
    /* 1000px */
    font-size: 3rem;
    left: 9.03%;
    top: 32.75vh;
  }

  @media screen and (min-width: 62.5em) and (max-height: 46rem) {
    font-size: 1.75rem !important;
  }

  @media screen and (min-width: 62.5em) and (min-height: 38em) and (max-height: 46rem) {
    font-size: 2rem !important;
  }

  @media screen and (min-width: 75em) and (min-height: 38em) and (max-height: 46rem) {
    font-size: 2.5rem !important;
  }

  @media screen and (min-width: 75em) {
    /* 1200px */
    font-size: 3.25rem;
    top: 35vh;
  }

  @media screen and (min-width: 90em) {
    /* 1440px */
    /* font-size: 3.5rem; */
    font-size: 3vw;
  }

  @media screen and (min-width: 120em) {
    /* 1920px */
    font-size: 4.667rem;
    top: 230px;
    width: 896.063px;
  }
`;

const HeroPageLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 62.5em) {
    align-items: flex-end;
    bottom: 2.5005em;
    flex-direction: row;
    justify-content: center;
    left: 50%;
    max-width: 73.75em;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100% - 1.876em);
  }
`;

const HeroPageLink = styled(Link).attrs(({ ariaLabel }) => ({
  ariaLabel
}))`
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.2em;
  color: ${({ inactiveFontColor }) => css`var(${inactiveFontColor})`};
  display: flex;
  font-size: 1.1em;
  font-weight: 800;
  height: 2.6em;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: background-color 290ms ease-in-out, color 290ms ease-in-out, height 290ms ease-in-out;
  user-select: none;
  width: calc(100% - 1.5em);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;

  @media screen and (min-width: 20.625em) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: 23.438em) {
    width: 17.25em;
  }

  @media screen and (max-width: 62.438em) {
    bottom: ${({ bottom }) => bottom};
    left: 50%;
    margin: 0 0.75em;
    position: absolute;
    transform: translate(calc(-50% - 0.75em));
  }

  @media screen and (min-width: 62.5em) {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.2em 0.2em 0 0;
    width: calc(100% / 3);
  }
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1;
  top: 1em;
  left: 0;
  width: 100%;
  max-width: 420px;
  height: 0%;
  opacity: 0;
  transition: height 490ms ease-in-out, opacity 490ms ease-in-out;
  pointer-events: none;
  ${({ patientStoryDropMenuIsOpen }) =>
    patientStoryDropMenuIsOpen &&
    css`
      pointer-events: auto;
      height: 100%;
      opacity: 1;
      box-shadow: -0.15em 1.5em 0.25em rgba(229, 229, 229, 0.75) !important;
    `}

  @media screen and (min-width: 62.5em) {
    top: 1.8em;
    ${({ patientStoryDropMenuIsOpen }) =>
      patientStoryDropMenuIsOpen &&
      css`
        pointer-events: auto;
        height: 100%;
        opacity: 1;
        box-shadow: -0.15em 2.2em 0.25em rgba(229, 229, 229, 0.75) !important;
      `}
  }

  @media screen and (min-width: 75em) {
    /* 1200px */
    top: 1.15em;
    ${({ patientStoryDropMenuIsOpen }) =>
      patientStoryDropMenuIsOpen &&
      css`
        pointer-events: auto;
        height: 100%;
        opacity: 1;
        box-shadow: -0.15em 1.5em 0.25em rgba(229, 229, 229, 0.75) !important;
      `}
  }

  @media screen and (min-width: 90em) {
    /* 1440px */
    top: 3.75rem;
    ${({ patientStoryDropMenuIsOpen }) =>
      patientStoryDropMenuIsOpen &&
      css`
        box-shadow: none !important;
      `}
  }

  @media screen and (min-width: 90em) and (min-height: 32em) and (max-height: 42rem) {
    /* 1440px */
    top: 3.75rem;
    ${({ patientStoryDropMenuIsOpen }) =>
      patientStoryDropMenuIsOpen &&
      css`
        box-shadow: -0.15em 2em 0.25em rgba(229, 229, 229, 0.75) !important;
      `}
  }
`;

const Menu = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  position: relative;
`;

const ActiveDownArrow = styled.div`
  background-color: var(${({ activeBackgroundColor }) => activeBackgroundColor});
  width: 1.667em;
  height: 1.667em;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
  transform-origin: 0 50%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  transition: bottom 290ms ease-in-out, opacity 290ms ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      bottom: -0.8335em;
      opacity: 1;
    `}
`;

export default Hero;
