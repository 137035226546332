import React from 'react';
import styled from 'styled-components';

export default ({ children, styles }) => <SubSectionH2 style={{...styles}}>{children}</SubSectionH2>

const SubSectionH2 = styled.h2`
  font-size: 2rem;
  margin-bottom: 0.5em;
  line-height: 1.475em;
  a {
    color: #000000;
    text-decoration: none;
  }
  @media screen and (min-width: 48em) {
    font-size: 3rem;
    line-height: 1.5em;
  }
`;
