import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

const LargeImg = ({ sources, alt, caption }) => (
  <Wrapper>
    <Image
      fluid={
        sources.length > 1
          ? [
              sources[0],
              sources[1] && {
                ...sources[1],
                media: '(min-width: 48em)'
              }
            ]
          : [sources[0]]
      }
      alt={alt.replace(/_/g, ' ')}
    />
    {caption && <Caption>{caption}</Caption>}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
`;

const Image = styled(Img)`
  max-width: 51.25em;
  width: 100%;
  height: 100%;
`;

const Caption = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  position: absolute;
  bottom: -2em;
  left: 0;
`;

export default LargeImg;
