import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import playIcon from './play-icon.png';

const VideoPlayer = ({
  title,
  vimeoCode,
  paddingTop = '56.25%',
  playingVideo,
  setPlayingVideo,
  thumbnail,
  hide
}) => {
  return (
    <>
      {playingVideo === vimeoCode ? (
        <AspectRatioWrapper paddingTop={paddingTop}>
          <iframe
            allow="autoplay; fullscreen"
            title={title}
            allowFullScreen
            frameBorder="0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
            height="100%"
            src={`https://player.vimeo.com/video/${vimeoCode}?transparent=true&autopause=0&autoplay=${
              playingVideo === vimeoCode
            }`}
            width="100%"
          />
        </AspectRatioWrapper>
      ) : (
        <div
          style={{
            position: 'relative'
          }}>
          {hide && (
            <DisclaimerVideoText>
              <p>
                Rosaline&apos;s video is in the process of being approved separately and will not be
                added till it is certified.
              </p>
            </DisclaimerVideoText>
          )}
          <Thumbnail
            fluid={thumbnail.childImageSharp.fluid}
            alt={thumbnail.name.replace(/_/g, ' ')}
            imgStyle={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
          {!hide && (
            <Underlay
              key={vimeoCode}
              initial={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 1 } }}
              onClick={() => setPlayingVideo(vimeoCode)}>
              <BigPlayButton
                whileHover={{ scale: 1.075, filter: 'brightness(107.5%)' }}
                initial={{ scale: 1, filter: 'brightness(100%)' }}
                whileTap={{ scale: 0.95 }}
                src={playIcon}
                alt="play icon"
              />
            </Underlay>
          )}
        </div>
      )}
    </>
  );
};

const BigPlayButton = styled.img`
  width: 20%;
  min-width: 75px;
  max-width: 120px;
  animation: pulse 1.2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      filter: brightness(100%);
      transform: scale(100%);
    }

    50% {
      filter: brightness(140%);
      transform: scale(107%);
    }

    100% {
      filter: brightness(100%);
      transform: scale(100%);
    }
  }
`;

const AspectRatioWrapper = styled.div`
  position: relative;
  padding-top: ${({ paddingTop }) => paddingTop};
  max-width: 820px;
  max-height: 460px;
  width: 100%;
  height: 100%;
`;

const DisclaimerVideoText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--amy-light-blue);
  color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    max-width: 75%;
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

const Thumbnail = styled(Img)`
  max-width: 820px;
  max-height: 460px;
  width: 100%;
  height: 100%;
`;

const Underlay = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default VideoPlayer;
