import * as React from 'react';
function SvgRibcageIcon(props) {
  return (
    <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M49.849 80.966c-.604 1.208-.907 2.719-.907 4.532 0 3.323 0 8.459 14.502 8.459 11.48 0 23.867-2.72 30.816-4.23v-4.834H65.559c-7.251 0-12.387-1.208-15.71-3.927zM54.38 67.673c-.906.605-2.114 2.115-2.416 3.928-.303 1.51 0 2.719.906 3.927 1.813 2.115 6.344 3.324 12.689 3.324h28.7v-6.043H80.666c-9.97 0-21.149-.906-26.284-5.136zM57.704 62.236c0 1.208 5.74 4.532 22.658 4.532h13.596v-9.064H80.362c-16.918 0-22.658 3.323-22.658 4.532zM54.683 126.283c0 .907 0 1.511.302 2.417.302 2.115 1.51 4.532 5.136 4.532 2.719 0 3.323-.907 5.438-4.532l.604-.906c.302-.302.302-.604.604-.907-1.51.303-3.625.605-5.438.605-2.719 0-4.834-.605-6.646-1.209zM48.943 97.281c-.303 1.209-.303 2.417-.303 3.625.303 2.719.605 8.158 14.502 8.158 11.782 0 24.17-5.136 30.816-8.158V95.77c-7.251 1.813-19.336 4.532-30.816 4.532-6.042 0-10.574-1.208-14.2-3.02zM142.296 62.236c0-1.209-5.74-4.532-22.659-4.532h-13.595v9.063h13.595c17.221 0 22.659-3.323 22.659-4.531zM51.662 113.595c0 .604 0 1.209.302 2.115.302 2.115 2.719 5.74 9.063 5.74 6.949 0 10.272-1.812 13.293-5.136.907-.906 1.51-1.812 2.417-2.417-4.532.907-9.063 2.115-13.595 2.115-4.532-.604-8.157-1.51-11.48-2.417zM134.743 84.893h-28.701v4.834c6.647 1.813 19.033 4.23 30.816 4.23 14.501 0 14.501-5.438 14.501-8.46 0-2.114-.604-3.625-1.208-4.531-3.021 2.719-8.459 3.927-15.408 3.927zM136.858 100.302c-11.48 0-23.565-2.719-30.816-4.532v5.136c6.647 3.022 19.033 8.158 30.816 8.158 13.897 0 14.199-5.136 14.501-8.158 0-1.51 0-2.719-.302-3.625-3.323 1.813-8.157 3.021-14.199 3.021z"
        fill="#0279A9"
      />
      <path
        d="M100 0C44.713 0 0 44.713 0 100s44.713 100 100 100 100-44.713 100-100S155.287 0 100 0zm57.704 101.813c-.302 3.323-1.813 6.042-3.625 8.157.302 1.51.906 3.625.302 6.646-.302 2.115-1.511 4.23-3.324 6.043.303 1.812.605 4.531 0 6.948-.906 6.043-5.136 9.668-11.178 9.668-6.344 0-8.459-3.928-10.574-7.553l-.604-.906c-2.115-3.626-2.719-4.532-7.553-10.574-4.532-4.532-10.876-9.97-12.387-11.179-.906-.302-1.812-.906-2.719-1.208v10.574h3.626c1.208 0 2.417.906 2.417 2.417v1.51c0 1.209-.907 2.417-2.417 2.417h-3.626v6.043h3.626c1.208 0 2.417.906 2.417 2.417v1.51c0 1.209-.907 2.417-2.417 2.417h-3.626v6.042h3.626c1.208 0 2.417.907 2.417 2.417v1.511c0 1.208-.907 2.417-2.417 2.417h-3.626v6.042H93.958v-6.042h-3.626c-1.208 0-2.417-.907-2.417-2.417v-1.511c0-1.208.907-2.417 2.417-2.417h3.626v-6.042h-3.626c-1.208 0-2.417-.906-2.417-2.417v-1.51c0-1.209.907-2.417 2.417-2.417h3.626v-6.043h-3.626c-1.208 0-2.417-.906-2.417-2.417v-1.51c0-1.209.907-2.417 2.417-2.417h3.626v-10.574c-.907.302-1.813.906-2.72 1.208-1.51 1.209-8.156 6.647-12.386 11.179-4.834 6.042-5.438 7.25-7.553 10.574l-.604.906c-2.115 3.625-4.23 7.553-10.574 7.553-6.042 0-10.272-3.625-11.178-9.668-.302-2.719-.302-5.438 0-6.948-1.813-1.813-3.021-3.928-3.324-6.043-.302-3.021 0-5.136.303-6.646-1.813-2.115-3.324-4.834-3.626-8.157-.302-3.928 1.209-6.949 1.813-9.064-.907-2.115-1.813-4.531-1.813-7.25 0-4.834 2.115-8.158 3.626-9.97-.303-1.51-.605-3.324-.303-4.834.907-4.532 3.928-6.949 6.043-8.46.302-8.459 15.71-10.271 28.7-10.271h13.596v-6.042h12.084v6.042h13.595c12.991 0 28.701 1.812 28.701 10.272 2.115 1.208 5.438 3.927 6.043 8.459.302 1.813 0 3.323-.302 4.834 1.51 1.812 3.625 5.136 3.625 9.97 0 2.719-.906 5.135-1.813 7.25.907 1.813 2.115 5.136 1.813 9.064z"
        fill="#0279A9"
      />
      <path
        d="M133.535 126.887c.302.303.302.605.604.907l.604.906c2.115 3.625 2.719 4.532 5.438 4.532 3.625 0 4.834-2.417 5.136-4.532 0-.906.302-1.51.302-2.417-1.813.604-3.927 1.209-6.344 1.209-2.417 0-4.23-.302-5.74-.605zM147.432 75.528c.906-.906 1.208-2.417.906-3.927-.302-1.813-1.51-3.323-2.417-3.928-5.136 4.23-16.314 5.136-26.284 5.136h-13.595v6.043h28.701c6.345 0 10.876-1.209 12.689-3.324zM123.263 113.596l2.417 2.416c3.021 3.324 6.344 5.136 13.293 5.136 6.646 0 8.761-3.625 9.063-5.74 0-.906.302-1.51.302-2.115-3.021 1.209-6.948 2.115-11.48 2.115s-9.063-.906-13.595-1.812z"
        fill="#0279A9"
      />
    </svg>
  );
}
export default SvgRibcageIcon;
