import React from 'react';
import styled, { css } from 'styled-components';
import { Link, navigate } from 'gatsby';
import { LinkContents } from 'components';
import rightFacingArrow from 'images/right_facing_arrow.svg';
import transition from 'styled-transition-group';

const Aside = ({
  activeAnchor,
  handleCarousel,
  handleSectionChange,
  pathname,
  scrollToAnchor,
  sectionTitlesFromCurrentPage,
  updateActiveAnchor,
  viewportWidth
}) => {
  const firstLinkDestination = pathname === '/' ? '/genetics-and-t80a-roots/' : '/';
  return sectionTitlesFromCurrentPage ? (
    <Wrapper>
      <Sections>
        <p>Sections</p>
        <ul>
          {sectionTitlesFromCurrentPage.map((sectionTitle) => {
            const anchor = `#${sectionTitle.split(' (')[0].replace(/'/g, '').replace(/ /g, '-')}`;
            return (
              <li key={anchor}>
                <SectionTitle
                  highlight={anchor === activeAnchor}
                  onClick={() => {
                    handleSectionChange({ sectionManuallySelected: true });
                    updateActiveAnchor(anchor);
                    scrollToAnchor(anchor.slice(1));
                    setTimeout(() => {
                      handleSectionChange({ sectionManuallySelected: null });
                    }, 1000);
                  }}>
                  <Bullet in={anchor === activeAnchor} />
                  {
                    sectionTitle
                      .split(' ')
                      .map((word) =>
                        word === 'and'
                          ? word.slice(0, 1) + word.slice(1)
                          : word.slice(0, 1).toUpperCase() + word.slice(1)
                      )
                      .join(' ')
                      .replace(/T80a/g, 'T80A')
                      .split('(')[0]
                  }
                </SectionTitle>
              </li>
            );
          })}
        </ul>
      </Sections>
      <Link
        to={firstLinkDestination}
        onClick={(e) => {
          e.preventDefault();
          updateActiveAnchor(null);
          scrollToAnchor('top');
          setTimeout(() => {
            navigate(firstLinkDestination);
            handleCarousel(firstLinkDestination);
            setTimeout(() => {
              scrollToAnchor(firstLinkDestination === '/' ? 'rosalines-story' : 'genetics');
            }, 580);
          }, 825);
        }}>
        <LinkContents
          backgroundColor="--amy-lightest-blue-transparent"
          text={pathname === '/' ? 'General Information' : 'Patient Stories'}
          fontSize={viewportWidth < 1100 ? '1rem' : '1.25rem'}
          fontWeight="600"
          height="3em"
          iconBackgroundColor="--amy-dark-blue"
          iconSVG={rightFacingArrow}
          iconSVGAlt="right facing arrow"
          iconSize="1.2em"
          textAlign="left"
        />
      </Link>
      {/* <a href="/" target="__blank">
        <LinkContents
          backgroundColor="--amy-lightest-blue-transparent"
          text="Download Brochure"
          fontSize={viewportWidth < 1100 ? '1rem' : '1.25rem'}
          fontWeight="600"
          height="3em"
          iconBackgroundColor="--amy-blue"
          iconSVG={rightFacingArrow}
          iconSVGAlt="right facing arrow"
          iconSize="1.2em"
          textAlign="left"
        />
      </a> */}
    </Wrapper>
  ) : null;
};

const Wrapper = styled.aside`
  font-size: 1.25rem;
  margin-top: 6.6em;
  position: -webkit-sticky;
  position: sticky;
  top: 5.833em;
  width: 27.12%;

  > a {
    border-radius: 0.167em;
    display: block;
    margin-top: 1em;
    overflow: hidden;
    text-decoration: none;
  }

  @media screen and (min-width: 68.75em) {
    font-size: 1.5rem;
    margin-top: 5.5em;
  }
`;

const Sections = styled.div`
  background-color: var(--amy-lightest-blue-transparent);
  border-radius: 0.167em;
  cursor: pointer;
  line-height: 1.917em;
  padding: 1.208em 0.604em 1.208em 1.208em;

  > p {
    font-weight: 700;
  }

  ul {
    list-style-type: none;
  }

  @media screen and (min-width: 68.75em) {
    padding: 1.208em;
  }
`;

const SectionTitle = styled.p`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  font-weight: 600;
  line-height: 1.425em;
  margin-left: 1.583em;
  position: relative;
  text-decoration: none;
  transition: color 150ms ease-in-out;

  ${({ highlight }) =>
    highlight
      ? css`
          color: var(--amy-light-blue);
        `
      : css`
          color: #000;
        `};
`;

const Bullet = transition.span.attrs({
  unmountOnExit: true,
  timeout: 150
})`
  background-color: var(--amy-light-blue);
  border-radius: 50%;
  height: 0.417em;
  left: -1.5em;
  position: absolute;
  width: 0.417em;

  &:enter {
    opacity: 0.01;
  }

  &:enter-active {
    opacity: 1;
    transition: all 150ms ease-in-out;
  }

  &:exit {
    opacity: 1;
  }

  &:exit-active {
    opacity: 0.01;
    transition: all 150ms ease-in-out;
  }
`;

export default Aside;
