const React = require('react');
const Layout = require('./src/components/Shared/Layout').default;

exports.wrapPageElement = ({ props }) => {
  const PageComponent = props.pageResources.component;
  return (
    <Layout {...props}>
      <PageComponent {...props} />
    </Layout>
  );
};

/* eslint-disable no-underscore-dangle */
// exports.onClientEntry = () => {
//   window.___INITIAL_RENDER = true;

//   const overlay = document.getElementById('___overlay');

//   overlay.addEventListener(
//     'transitionend',
//     () => {
//       overlay.style.display = 'none';
//       window.removeEventListener('transitionend', overlay);
//       window.___INITIAL_RENDER = false;
//     },
//     false
//   );

//   window.fadeOutOverlay = function () {
//     overlay.style.opacity = '0';
//   };
// };
