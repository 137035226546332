import React from 'react';
import styled from 'styled-components';

export default ({ children }) => <SubSectionH3>{children}</SubSectionH3>;

const SubSectionH3 = styled.h3`
  font-size: 1.5rem;
  margin: 1.75em 0 0.75em;
  line-height: 1.5em;

  @media screen and (min-width: 48em) {
    font-size: 2rem;
    margin: 1.75em 0 0.75em;
    line-height: 1.475em;
  }
`;
