import * as React from 'react';
function SvgBrainIcon(props) {
  return (
    <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M66.163 75.529c3.928-2.417 7.553-2.417 7.855-2.417.605 0 1.209-.604 1.209-1.209 0-.604-.604-1.208-1.209-1.208-.302 0-4.531-.302-9.063 2.719-4.532 2.719-6.344 7.25-6.344 7.553 0 0-1.511 3.323-1.209 6.646 0 .605.604 1.209 1.209 1.209.604 0 1.208-.604 1.208-1.209 0-2.719 1.208-5.438 1.208-5.438-.302 0 1.51-3.927 5.136-6.646zM81.873 121.45s-5.438 3.625-11.48 1.813c0 0-4.532-1.209-7.553-5.438-.302-.605-1.209-.605-1.813-.303-.604.303-.604 1.209-.302 1.813 3.625 5.136 8.761 6.345 9.063 6.345 1.511.302 2.72.604 4.23.604 5.136 0 9.064-2.719 9.366-2.719.604-.302.604-1.209.302-1.813-.605-.604-1.209-.604-1.813-.302zM103.323 78.852c-.604 0-1.208.302-1.208 1.209v.906c-.605 8.761-8.157 15.71-17.22 15.71-8.158 0-15.107-5.438-16.92-12.689v-.302c0-.604-.604-1.208-1.51-.906-.604 0-1.208.604-.906 1.51v.605c2.115 8.459 9.97 14.501 19.335 14.501h2.115c-1.813 1.813-3.626 4.834-3.928 9.668 0 .604.605 1.208 1.209 1.208.604 0 1.208-.604 1.208-1.208.302-6.949 5.136-9.668 5.136-9.97.302-.302.605-.604.605-.906 7.552-2.417 12.99-9.366 13.293-17.22v-.605c0-.604-.605-1.209-1.209-1.51z"
        fill="#0279A9"
      />
      <path
        d="M91.541 82.78c.604 0 1.208-.604 1.208-1.209 0-3.323 1.511-5.438 1.511-5.438 3.323-4.834 8.459-4.531 8.459-4.531.604 0 1.209-.605 1.209-1.209 0-.604-.605-1.208-1.209-1.208-.302 0-6.646-.303-10.574 5.438 0 0-2.115 2.719-2.115 6.948.302.605.605 1.209 1.511 1.209zM140.483 75.83c-.302-.603-1.208-.906-1.51-.301-5.136 3.02-9.97 1.208-9.97 1.208-4.834-1.51-7.251-5.74-7.251-5.74-.302-.604-.906-.906-1.51-.604-.604.302-.907.906-.604 1.51 0 .302 2.719 5.136 8.459 6.949 0 0 1.812.604 4.531.604 2.115 0 4.834-.302 7.553-2.115.605 0 .907-.906.302-1.51zM115.106 93.353c-.302 0-4.834 3.021-5.438 8.157-.604 5.136 1.51 8.157 1.812 8.46.303.302.605.604.907.604.302 0 .604 0 .604-.302.604-.302.604-1.209.302-1.813 0 0-1.812-2.719-1.208-6.647.604-3.927 4.531-6.646 4.531-6.646.605-.302.605-1.209.303-1.51-.605-.605-1.209-.605-1.813-.303z"
        fill="#0279A9"
      />
      <path
        d="M100 0C44.713 0 0 44.713 0 100s44.713 100 100 100 100-44.713 100-100S155.287 0 100 0zm40.786 115.106c-1.209 0-2.72 0-3.928-.302h-.604c-1.209.302-2.417.604-3.928.906a11.448 11.448 0 000-4.532c-.906-5.74-5.438-10.574-11.48-12.689-.604.302-.906.907-.906 1.209v.906c5.438 1.813 9.365 6.043 10.272 10.876.302 2.417 0 4.834-.907 6.949-.906 1.813-1.813 3.323-3.021 4.834-3.021 3.323-7.553 5.74-12.991 6.646-.906 0-1.813.302-2.417.302h-3.021s-.302 0-.302.303c-9.97 1.208-20.846 10.876-20.846 22.96v.605H72.81l1.812-13.596-1.51.303h-4.23c-9.365-.907-17.22-7.553-19.637-16.919-.302-1.208-.604-2.719-.604-3.625v-.302-1.209-1.208c0-8.46 5.438-16.012 12.99-18.731.605-.303.907-.907.605-1.511-.302-.604-.907-.906-1.51-.604l-.907.302c-7.553 3.323-12.991 10.574-13.293 19.033-5.74-2.719-9.366-8.459-9.366-14.501 0-2.417.302-4.532 1.209-6.345l.302-.604-.302-.604c-.907-2.115-1.511-4.532-1.511-6.345 0-6.042 3.625-11.178 8.761-13.595l.605-.302v-.604c1.208-8.46 8.459-15.106 16.616-15.71h.604l.302-.604c2.417-4.834 7.251-7.855 12.387-7.855h1.813c5.74.604 10.272 6.042 10.876 12.688v1.209c0 .604.302 1.208 1.208 1.208.605 0 1.209-.604 1.209-1.208v-.907c-.302-6.042-3.324-11.178-7.855-13.595 2.417-1.51 5.136-2.416 7.855-2.416 3.02 0 5.74.906 7.855 2.416l.604.303.604-.303c2.417-1.208 5.136-1.51 8.157-1.51 5.74 0 10.876 2.417 14.502 6.646l.604.605.604-.303c1.209-.302 2.417-.302 3.626-.302 4.833 0 9.365 2.417 11.782 6.647l.302.302H143.202s.303 0 .303.302h1.51c.302 0 .302.302.604.302s.302.302.605.302l.906.605a16.018 16.018 0 016.344 6.344c.907 1.51 1.511 3.021 1.813 4.834v2.417c0 7.553-5.74 13.595-12.991 13.595-.906 0-1.813 0-2.417-.302h-.302c-.604-.302-1.208-.302-1.511.302-.302.604-.302 1.208.303 1.51.302 0 4.833 3.324 4.229 9.668 0 .604.302 1.209.907 1.209.604 0 1.208-.303 1.208-.907.604-4.23-.906-7.25-2.417-9.063 7.553 0 13.897-6.042 14.804-13.595 3.323 3.927 5.136 9.063 5.136 14.199.906 12.991-9.064 22.961-21.45 22.961z"
        fill="#0279A9"
      />
    </svg>
  );
}
export default SvgBrainIcon;
