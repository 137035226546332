import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import siteThumbnail from '../../../images/hattr_amyloidosis_thumbnail.jpg';

export default function SEO({
  children,
  lang,
  meta,
  pageSpecificTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt,
  pathname
}) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteTitle
              siteDescription
              siteThumbnailImage
              siteThumbnailAlt
              siteUrl
              twitterPage
              author
            }
          }
        }
      `}
      render={(data) => {
        const {
          siteTitle,
          siteDescription,
          siteThumbnailAlt,
          siteUrl,
          twitterPage,
          author
        } = data.site.siteMetadata;
        const title = pageSpecificTitle || siteTitle;
        const description = pageSpecificDescription || siteDescription;
        const thumbnail = pageSpecificThumbnail || siteThumbnail;
        const thumbnailAlt = pageSpecificThumbnailAlt || siteThumbnailAlt;
        const url = `${siteUrl}${pathname}`;
        const image = `${siteUrl}${thumbnail}`;
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={`${siteTitle} | %s`}
            link={[
              {
                rel: 'canonical',
                href: url
              }
            ]}
            meta={[
              {
                property: 'og:site_name',
                content: siteTitle
              },
              {
                name: `description`,
                content: `${description}`
              },
              {
                property: `og:locale`,
                content: 'en_GB'
              },
              {
                property: `og:url`,
                content: url
              },
              {
                property: `og:title`,
                content: `${siteTitle} | ${pageSpecificTitle}`
              },
              {
                property: `og:description`,
                content: description
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:image`,
                content: image
              },
              {
                property: `og:image:url`,
                content: image
              },
              {
                property: `og:image:secure_url`,
                content: image
              },
              {
                property: `og:image:alt`,
                content: thumbnailAlt
              },
              {
                property: 'og:image:width',
                content: '1200'
              },
              {
                property: 'og:image:height',
                content: '630'
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:image`,
                content: image
              },
              {
                property: 'twitter:image:alt',
                content: thumbnailAlt
              },
              {
                name: `twitter:site`,
                content: twitterPage
              },
              {
                name: `twitter:creator`,
                content: twitterPage
              },
              {
                name: `twitter:title`,
                content: `${siteTitle} | ${pageSpecificTitle}`
              },
              {
                name: `twitter:description`,
                content: `${description}`
              },
              {
                name: `author`,
                content: author
              }
            ].concat(meta)}>
            {children}
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: []
};
