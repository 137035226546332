import React from 'react';
import styled from 'styled-components';

export default ({ children, id, className, styles }) => (
  <SectionTitle style={{ ...styles }} id={id} className={`section-title ${className}`}>
    {children}
  </SectionTitle>
);

const SectionTitle = styled.p`
  color: var(--amy-light-blue);
  text-transform: uppercase;
  font-size: 0.875rem;
  margin: 4.05em 0 1.42em; /* 56.7px 0 19.88px */
  font-weight: 700;
  line-height: 100%;

  a {
    color: var(--amy-light-blue);
    text-decoration: none;
  }

  @media screen and (min-width: 48em) {
    font-size: 1.5rem;
    margin: 6.5em 0 1.15em; /* 156px 0 27.6px */
  }
`;
