import React from 'react';
import styled, { css } from 'styled-components';

const LinkContents = ({
  backgroundColor = '--amy-light-grey',
  border = 'none',
  borderRadius = '0',
  centerText,
  fontSize,
  fontWeight = '700',
  height,
  iconBackgroundColor,
  iconBorderRadius = null,
  iconSVG,
  iconSVGAlt,
  iconSize,
  text,
  textAlign,
  padding = '0',
  margin = '0',
  maxWidth = '100%',
  minWidth,
  patientStoryDropMenuIsOpen = false
}) => (
  <Wrapper
    backgroundColor={backgroundColor}
    border={border}
    borderRadius={borderRadius}
    height={height}
    centerText={centerText}
    fontSize={fontSize}
    margin={margin}
    maxWidth={maxWidth}
    minWidth={minWidth}
    padding={padding}
    patientStoryDropMenuIsOpen={patientStoryDropMenuIsOpen}>
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      dangerouslySetInnerHTML={{ __html: text }}
      height={height}
      textAlign={textAlign}
    />
    {iconSVG && (
      <IconWrapper
        height={height}
        iconSize={iconSize}
        iconBackgroundColor={iconBackgroundColor}
        iconBorderRadius={iconBorderRadius}
        patientStoryDropMenuIsOpen={patientStoryDropMenuIsOpen}>
        <Img src={iconSVG} alt={iconSVGAlt} iconSize={iconSize} />
      </IconWrapper>
    )}
  </Wrapper>
);

const IconWrapper = styled.div`
  align-items: center;
  background-color: var(${({ iconBackgroundColor }) => iconBackgroundColor});
  border-radius: ${({ iconBorderRadius }) => iconBorderRadius};
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
  position: absolute;
  right: ${({ iconBorderRadius }) => (iconBorderRadius ? '-1px' : '0')};
  top: ${({ iconBorderRadius }) => (iconBorderRadius ? '-1px' : '0')};
  width: ${({ height: width }) => width};
  transform: rotate(0);
  transition: all 300ms ease-in-out;
  ${({ patientStoryDropMenuIsOpen }) =>
    patientStoryDropMenuIsOpen &&
    css`
      transform: rotate(90deg);
    `};
`;

const Img = styled.img`
  height: ${({ iconSize }) => iconSize};
  width: ${({ iconSize }) => iconSize};
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: var(${({ backgroundColor }) => backgroundColor});
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  display: flex;
  flex-grow: 1;
  font-size: ${({ fontSize }) => fontSize};
  height: ${({ height }) => height};
  position: relative;
  width: 100%;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  ${({ centerText }) =>
    centerText
      ? css`
          justify-content: center;
        `
      : css`
          padding-left: 0.769em;
        `};

  &:hover {
    ${IconWrapper} {
      filter: contrast(122.5%);
      transition: filter 200ms ease-in-out;

      img {
        transform: scale3d(1.125, 1.125, 1.125);
        transition: transform 200ms ease-in-out;
      }
    }
  }

  &:not(:hover) {
    ${IconWrapper} {
      filter: contrast(100%);
      transition: filter 200ms ease-in-out;

      img {
        transform: scale3d(1, 1, 1);
        transition: transform 200ms ease-in-out;
      }
    }
  }
  @media screen and (min-width: 62.5em) {
    /* 1000px */
    min-width: ${({ minWidth }) => minWidth};
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

const Text = styled.div`
  color: #000;
  font-weight: ${({ fontWeight }) => fontWeight};
  margin-right: calc(${({ height: offset }) => offset } + 1rem);
  text-align: ${({ textAlign = 'center' }) => textAlign};
  text-shadow: none;
  text-decoration: none;
  p:nth-child(2) {
    font-size: 0.55em;
    font-weight: 400;
    margin-right: 1em;
  }
  strong {
    font-size: ${({ fontSize }) => fontSize};
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
  }
`;

export default LinkContents;
