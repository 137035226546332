import * as React from 'react';
function SvgNoteIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={1}
        y={1}
        width={18}
        height={18}
        rx={2}
        stroke="#fff"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path d="M10 4v8" stroke="#fff" strokeWidth={2} />
      <path d="M10 14v2" stroke="#fff" strokeWidth={2} strokeLinejoin="round" />
    </svg>
  );
}
export default SvgNoteIcon;
