import React from 'react';
import styled from 'styled-components';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

const DanielPopup = () => {
  const [playingDanielVideo, setPlayingDanielVideo] = React.useState(true);

  const modalToggle = () => {
    setPlayingDanielVideo(false);
  };

  return (
    <Container>
      <Rodal visible={playingDanielVideo} onClose={modalToggle} closeMaskOnClick>
        <AspectRatioWrapper>
          <iframe
            allow="autoplay; fullscreen"
            title="Daniel Video"
            allowFullScreen
            frameBorder="0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
            height="100%"
            src={`https://player.vimeo.com/video/1022560894?transparent=true&autopause=1&muted=${!playingDanielVideo}`}
            width="100%"
          />
        </AspectRatioWrapper>
        <p>
          Watch our new disease awareness video featuring Daniel O'Donnell, Rosaline Callaghan who
          is living with hATTR amyloidosis and Professor Aisling Ryan who is a Consultant Neurologist in Cork
          University Hospital.
        </p>
      </Rodal>
    </Container>
  );
};

const Container = styled.div`
  .rodal,
  .rodal-mask {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed !important;
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .rodal-mask {
    background-color: rgba(245, 245, 245, 0.7);
    transition: background-color 0.3s ease-in-out;
  }

  .rodal-dialog {
    display: flex;
    margin: auto;
    height: auto !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    font-family: inherit;
    font-size: 1rem;
    padding: 0.5em;
    border-radius: 0.3125em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative !important;
    width: 32em;
    max-width: 100%;
  }

  p {
    color: rgb(2, 121, 169);
    font-size: 1.063rem;
    line-height: 1.5em;
    margin: 1.5rem auto 0.875rem;
  }
`;

const AspectRatioWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  max-width: 820px;
  max-height: 460px;
  width: 100%;
  height: 100%;
`;

export default DanielPopup;
