import React from 'react';
import { Link, navigate } from 'gatsby';
import { LinkContents } from 'components';
import rightFacingArrow from 'images/right_facing_arrow.svg';
import styled from 'styled-components';

const formatLink = (link) =>
  link
    .replace(/\//g, '')
    .replace(/and/g, '+')
    .split('-')
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/T60a/g, 'T60A')
    .replace(/T80a/g, 'T80A');

export default ({ links, viewportWidth, handleCarousel, updateActiveAnchor }) => (
  <LinksToLearnMore>
    <div>
      {links.map((link) => (
        <Link
          to={link}
          key={link}
          onClick={(e) => {
            e.preventDefault();
            navigate(link);
            handleCarousel(link);
            updateActiveAnchor(null);
          }}>
          <LinkContents
            text={link === '/' ? 'Patient Stories + Resources' : formatLink(link)}
            centerText
            fontSize={viewportWidth < 450 ? '0.875rem' : viewportWidth < 1200 ? '1rem' : '1.25rem'}
            height="3.429em"
            iconBackgroundColor="--amy-blue"
            iconSVG={rightFacingArrow}
            iconSVGAlt="right facing arrow"
            iconSize={viewportWidth < 450 ? '1.371em' : viewportWidth < 1200 ? '1.5em' : '1.2em'}
          />
        </Link>
      ))}
    </div>
    <div>
      {/* <a href="/" target="__blank">
        <LinkContents
          text={viewportWidth < 1200 ? 'Download<br />Brochure' : 'Download Brochure'}
          centerText
          fontSize={viewportWidth < 450 ? '0.875rem' : viewportWidth < 1200 ? '1rem' : '1.25rem'}
          height="3.429em"
          iconBackgroundColor="--amy-blue"
          iconSVG={rightFacingArrow}
          iconSVGAlt="right facing arrow"
          iconSize={viewportWidth < 450 ? '1.371em' : viewportWidth < 1200 ? '1.5em' : '1.2em'}
        />
      </a> */}
      <a href="https://www.hattrbridge.eu/" target="__blank">
        <LinkContents
          text="'The Bridge' Website"
          centerText
          fontSize={viewportWidth < 450 ? '0.875rem' : viewportWidth < 1200 ? '1rem' : '1.25rem'}
          height="3.429em"
          iconBackgroundColor="--amy-lightest-blue"
          iconSVG={rightFacingArrow}
          iconSVGAlt="right facing arrow"
          iconSize={viewportWidth < 450 ? '1.371em' : viewportWidth < 1200 ? '1.5em' : '1.2em'}
        />
      </a>
    </div>
  </LinksToLearnMore>
);

const LinksToLearnMore = styled.div`
  max-width: 25em;
  margin: 0 auto;

  > div a {
    border-radius: 0.25em;
    overflow: hidden;
    display: block;
    text-decoration: none;
  }

  > div:first-of-type {
    a {
      margin-bottom: 1.25em;
    }

    @media screen and (min-width: 75em) {
      display: flex;
      justify-content: space-between;

      a {
        flex-grow: 1;
        max-width: 48.7%;
      }
    }
  }

  > div:last-of-type {
    display: flex;
    justify-content: space-between;

    a {
      flex-grow: 1;
      max-width: 48.7%;
    }
  }

  @media screen and (min-width: 75em) {
    max-width: 100%;
  }
`;
